
.navbar-custom {
  background-color: #3a411a;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 800px) {
  html {
    font-size: 18px;
  }
}

.navbar-logo{
  height: 3em;
  padding-right: 5px;
}
@media (max-width: 1000px) {
  .navbar-logo {
    display: none;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem #575656;
}
