.map-container {
    height: calc(100vh - 57px);
    width: 100%;
    position: absolute;
    left: 0;
    overflow: hidden;
}

.map-marker {
    //Probably a better way? squish the icon by modifying the box. width = half the icon
    width: 73px;
    height: 236px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    cursor: pointer;
}

.ag-theme-alpine {
    height: 100%;
}

.table-container {
    min-height: 600px;
    height: 40vh;
    width: 100%;
}

.deviceButtonHeader {
    display: flex;
    width: 100%;

    .flexitem {
        padding: 10px;
    }

    .textWindow {
        flex-basis: 30%;
    }

    .firebutton {
        margin-left: auto;
    }
}

.full-width-panel {
    height: 100%;
    width: 100%;
    background-color: lightgray;
    display: flex;
    flex-wrap: wrap;

    .datatable {
        display: flex;
        flex-basis: 60%;
        flex-grow: 1;

        tbody {
            width: inherit;
        }

        td:last-child {
            width: 100%;
        }
    }
}

.map-mini-container {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-basis: 40%;
}

.map-mini-sidebar {
    background-color: rgba(35, 55, 75, 0.151);
    color: rgb(0, 0, 0);
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    margin: 6px;
    border-radius: 4px;
    text-align: right;
}

.listNoIndentation {
    list-style-position: inside;
    padding: 0;
}

.btn-grid {
    background-color: red;
    color: white;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
    margin: 2px;
    line-height: normal;
    min-width: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    border-image: initial
}

.map-fire-btn {
    color: white;
    font-size: large;
    padding: 10px;
    background-color: red;
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: black;
}


.input-error {
    border: 2px solid #eb516d;
}

.AddToHomeImages {
    width: 30%;
    border-radius: 8px;
    padding: 5px;
}